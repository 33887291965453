//imports
window.$ = window.jQuery = require("jquery");
import velocity from 'velocity-animate';
import Section from './classes/section.js';


//Init
$(document).ready(function () {

    let meSection = new Section();
    
});

// window.addEventListener('scroll', function(e){
//     parallax('ghost-one','one')
//     parallax('ghost-two','two')
//     parallax('ghost-three','three')
//     parallax('ghost-four','four')
//     parallax('ghost-five','five')
//     parallax('ghost-six','six')
// }); 

// let oldScroll = 0;
// let newScroll = 0;
// function parallax(el,num) {
//     let newVal = 0;
//     var scrolled = window.pageYOffset;
//     var ghost = document.getElementById(el);
    
//     newScroll = scrolled;
//     newVal = scrolled * 0.3;

//     if(num === 'one'){
//         ghost.style.transform = 'translateY('+ newVal + 'px)';
//         ghost.style.opacity = newVal*.005;
//     } 
//     else if(num === 'two'){
//         ghost.style.transform = 'translateY('+ .8*newVal + 'px)';
//         ghost.style.opacity = newVal*.002;
//     } 
//     else if(num === 'three'){
//         ghost.style.transform = 'translateY('+ .6*newVal + 'px)';
//         ghost.style.opacity = newVal*.001;
//     }
//     else if(num === 'four'){
//         ghost.style.transform = 'translateY(-'+ newVal + 'px)';
//         ghost.style.opacity = newVal*.005;
//     }
//     else if(num === 'five'){
//         ghost.style.transform = 'translateY(-'+ .8*newVal + 'px)';
//         ghost.style.opacity = newVal*.002;
//     } 
//     else if(num === 'six'){
//         ghost.style.transform = 'translateY(-'+ .6*newVal + 'px)';
//         ghost.style.opacity = newVal*.001;
//     } 

//     oldScroll = newScroll;
// };
